import React, {useCallback, useMemo} from "react";
import {
  Cartesian2,
  Cartesian3,
  Cartographic,
  Color,
  ColorMaterialProperty,
  Entity as CesiumEntity,
  JulianDate,
  LabelStyle,
  NearFarScalar,
  Property,
  PropertyBag,
  VerticalOrigin
} from "cesium";
import {CesiumMovementEvent, EllipseGraphics, EllipsoidGraphics, Entity, LabelGraphics, PointGraphics} from "resium";
import {WithTranslation, withTranslation} from "react-i18next";
import {CollarAppearance, ICollarStyle} from "../../domain/ICollarStyle";
import {ColorUtil} from "../../../../lib/ColorUtil";
import {HoleLabelType} from "./HoleLabelSelector";
import {AppConstants} from "../../../../AppConstants";

interface IProps extends WithTranslation {
  show: boolean;
  holeLabels: HoleLabelType;
  name: string;
  holeName?:string|undefined;
  drillName?:string|undefined;
  labelColor?: Color|undefined;
  description?:string|Property;
  position: Cartesian3;
  collarStyle: ICollarStyle;
  color: Color;
  properties?: PropertyBag | {[key: string]: any;}
  onLeftClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onRightClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onDoubleClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseMove?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseUp?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseDown?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
}

const PureCollarEntity: React.FC<IProps> = (props) => {
  // console.log("PureCollarEntity: rendering");
  const radii = props.collarStyle.collarDiameter / 2 ;

  const color = props.color;
  const show = props.show;
  const holeLabels = props.holeLabels;
  const holeName = props.holeName;
  const drillName = props.drillName;

  const holeLabel = useMemo(()=>{
    if ( !show || holeLabels === HoleLabelType.None ) return undefined ;
    switch( holeLabels ) {
      case HoleLabelType.HoleName:
        return holeName;
      case HoleLabelType.DrillName:
        return drillName;
    }
  }, [show, holeLabels, holeName, drillName]);

  const outlineColor = useMemo(()=>Color.BLACK.withAlpha(0.5 * color.alpha), [color])
  // const outlineColor = useMemo(()=>getTintedColor(color), [color])

  const labelColor = useMemo(()=>props.labelColor ?? Color.WHITE, [props.labelColor]);
  const labelBackColor = useMemo(()=>{
    return labelColor ? Color.fromCssColorString(ColorUtil.contrastingColor(labelColor.toCssHexString())) : undefined;
  }, [labelColor]);

  const onLeftClick = props.onLeftClick ;
  const handleLeftClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( onLeftClick ) {
      onLeftClick( movement, target );
    }
  }, [onLeftClick]);

  const onRightClick = props.onRightClick ;
  const handleRightClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( onRightClick ) {
      onRightClick( movement, target );
    }
  }, [onRightClick]);

  const onDoubleClick = props.onDoubleClick ;
  const handleDoubleClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( onDoubleClick ) {
      onDoubleClick( movement, target );
    }
  }, [onDoubleClick]);

  const onMouseMove = props.onMouseMove ;
  const handleMouseMove = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( onMouseMove ) {
      onMouseMove( movement, target );
    }
  }, [onMouseMove]);

  const onMouseUp = props.onMouseUp ;
  const handleMouseUp = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( onMouseUp ) {
      onMouseUp( movement, target );
    }
  }, [onMouseUp]);

  const onMouseDown = props.onMouseDown ;
  const handleMouseDown = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( onMouseDown ) {
      onMouseDown( movement, target );
    }
  }, [onMouseDown]);

  return (
      <>
        <Entity
            show={props.show}
            name={props.name}
            position={props.position}
            description={props.description}
            properties={props.properties}
            onClick={handleLeftClick}
            onRightClick={handleRightClick}
            onDoubleClick={handleDoubleClick}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseDown={handleMouseDown}
        >
          {
            {
              "Disk": (
                  <EllipseGraphics
                      show={props.show}
                      semiMinorAxis={radii}
                      semiMajorAxis={radii}
                      height={Cartographic.fromCartesian(props.position).height}
                      material={color}
                      outline={true}
                      outlineColor={outlineColor}
                      outlineWidth={5}
                  />
              ),
              "Sphere": (
                  <EllipsoidGraphics
                      show={props.show}
                      radii={new Cartesian3(radii,radii,radii)}
                      material={color}
                  />
              ),
              "Point": (
                  <PointGraphics
                      show={props.show}
                      color={color}
                      outlineColor={color}
                      outlineWidth={0.5}
                      pixelSize={5}
                      scaleByDistance={{
                        near: 10,
                        nearValue: 5,
                        far: 500,
                        farValue: 0.5
                      } as NearFarScalar}
                  />
              )
            } [CollarAppearance[props.collarStyle.collarAppearance]]
          }
          { AppConstants.OptionalFeatures.holeLabels && holeLabel &&
            <LabelGraphics
                text={holeLabel}
                style={LabelStyle.FILL}
                outlineColor={labelColor}
                outlineWidth={2}
                verticalOrigin={VerticalOrigin.BOTTOM}
                pixelOffset={new Cartesian2(0, -25)}
                pixelOffsetScaleByDistance={new NearFarScalar( 1.5e2, 1, 1.5e3, 0.1) }
                fillColor={labelColor}
                // scale={0.35}
                backgroundColor={(labelBackColor ?? Color.BLACK).withAlpha(0.5)}
                showBackground={true}
                backgroundPadding={Cartesian2.fromArray([15, 10])}
                scaleByDistance={new NearFarScalar(1.5e1, 0.75, 1.5e3, 0.01)}
            />
          }
        </Entity>
      </>
  )
}

export default withTranslation()(React.memo(PureCollarEntity));

export function getCollarColor( entity: CesiumEntity ): Color|undefined {
  if ( entity.ellipse?.material ) {
    return entity.ellipse.material.getValue(JulianDate.fromDate(new Date())).color;
  }
  else if ( entity.ellipsoid?.material ) {
    return entity.ellipsoid.material.getValue(JulianDate.fromDate(new Date())).color;
  }
  else if ( entity.point?.color ) {
    return entity.point.color.getValue(JulianDate.fromDate(new Date())).color;
  }
  else {
    return undefined;
  }
}
export function setCollarColor( entity: CesiumEntity, color: Color ) {
  // console.log(`setCollarColor: ${color}`)
  if ( entity.ellipse ) {
    entity.ellipse.material = new ColorMaterialProperty( color ) ;
  }
  else if ( entity.ellipsoid ) {
    entity.ellipsoid.material = new ColorMaterialProperty( color ) ;
  }
  if ( entity.point ) {
    entity.point.color = new ColorMaterialProperty( color ) ;
  }
}

